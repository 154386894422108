<template>
<!-- eslint-disable max-len -->
  <div class="col px-2 pt-4">
    <div class="card text-center">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item pointer-on-hover">
            <a class="nav-link "
              :class="{ active: activeTab === 'profile' }"
              @click="activeTab='profile'"
            >
              Profile
            </a>
          </li>
          <!-- <li class="nav-item pointer-on-hover">
            <a class="nav-link"
              :class="{ active: activeTab === 'preferences' }"
              @click="activeTab='preferences'"
            >
              Preferences
            </a>
          </li> -->
        </ul>
      </div>
      <div class="card-body mt-4">
        <form v-show="activeTab === 'profile'">
          <div class="form-group row">
            <label for="primaryEmail" class="col-sm-2 col-form-label">Email</label>
            <div class="col-sm-8">
              <input type="text"
                class="form-control"
                id="primaryEmail"
                aria-describedby="primaryEmailFeedback"
                v-model="primaryEmail"
                :class="{ 'is-invalid': profile && profile.user && profile.user.email_error }"
              >
              <div id="primaryEmailFeedback" class="invalid-feedback">
                {{ profile && profile.user && profile.user.email_error }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="firstName" class="col-sm-2 col-form-label">First Name</label>
            <div class="col-sm-8">
              <input type="text"
                class="form-control"
                id="firstName"
                aria-describedby="firstNameFeedback"
                v-model="firstName"
                :class="{ 'is-invalid': profile && profile.user && profile.user.first_name_error }"
              >
              <div id="firstNameFeedback" class="invalid-feedback">
                {{ profile && profile.user && profile.user.first_name_error }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="lastName" class="col-sm-2 col-form-label">Last Name</label>
            <div class="col-sm-8">
              <input type="text"
                class="form-control"
                id="lastName"
                aria-describedby="lastNameFeedback"
                v-model="lastName"
                :class="{ 'is-invalid': profile && profile.user && profile.user.last_name_error }"
              >
              <div id="lastNameFeedback" class="invalid-feedback">
                {{ profile && profile.user && profile.user.last_name_error }}
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="igHandle" class="col-sm-2 col-form-label">IG Handle</label>
            <div class="col-sm-8">
              <input type="text"
                class="form-control"
                id="igHandle"
                aria-describedby="igHandleFeedback"
                v-model="igHandle"
                :class="{ 'is-invalid': profile && profile.ig_handle_error }"
              >
              <div id="igHandleFeedback" class="invalid-feedback">
                {{ profile && profile.ig_handle_error }}
              </div>
            </div>
          </div>
          <div class="form-group row pt-3">
            <template v-if="isProfileLoading">
              <div class="col text-center">
                <div class="spinner-border text-danger" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="col text-right">
                <button class="btn btn-sm"
                  @click.prevent="actionSaveChanges"
                  :disabled="isProfileLoading"
                >
                  Save Changes
                </button>
              </div>
              <div class="col text-left">
                <button class="btn btn-sm bg-light text-dark btn-outline-dark"
                  @click.prevent="actionDiscardChanges"
                  :disabled="isProfileLoading"
                >
                  Discard Changes
                </button>
              </div>
            </template>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Utils from '@/utils';

export default {
  data() {
    return {
      activeTab: 'profile',
      profile: {
        avatar: '',
        settings: '',
        user: {
          first_name: '',
          last_name: '',
          email: '',
        },
        last_updated: '',
        contact: '',
        ig_handle: '',
        secondary_email: '',
        timezone: 'Africa/Abidjan',
        dob: '2021-07-26',
      },
    };
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.getProfile();
      next();
    });
  },

  beforeRouteUpdate(to, from, next) {
    this.getProfile();
    next();
  },

  computed: {
    ...mapState('profile', {
      isProfileLoading: 'isLoading',
    }),

    avatar: {
      set(value) {
        this.$set(this.profile, 'avatar', value);
      },
      get() {
        return this.profile.avatar;
      },
    },
    firstName: {
      set(value) {
        this.$set(this.profile.user, 'first_name', value);
      },
      get() {
        return this.profile.user.first_name;
      },
    },
    lastName: {
      set(value) {
        this.$set(this.profile.user, 'last_name', value);
      },
      get() {
        return this.profile.user.last_name;
      },
    },
    primaryEmail: {
      set(value) {
        this.$set(this.profile.user, 'email', value);
      },
      get() {
        return this.profile.user.email;
      },
    },
    timezone: {
      set(value) {
        this.$set(this.profile, 'timezone', value);
      },
      get() {
        return this.profile.timezone;
      },
    },
    dob: {
      set(value) {
        this.$set(this.profile, 'dob', value);
      },
      get() {
        return this.profile.dob;
      },
    },
    igHandle: {
      set(value) {
        this.$set(this.profile, 'ig_handle', value);
      },
      get() {
        return this.profile.ig_handle;
      },
    },
  },

  methods: {
    getProfile() {
      return this.$store.dispatch('profile/getProfile')
        .then((response) => {
          const { data, success } = response;
          if (success) {
            this.updateData(data);
          }
        });
    },

    updateData(data) {
      this.profile = {
        user: {},
      };
      Object.keys(data).forEach((key) => {
        if (key === 'user') {
          const user = data[key];
          Object.keys(user).forEach((k) => {
            this.$set(this.profile.user, k, user[k]);
          });
        } else {
          this.$set(this.profile, key, data[key]);
        }
      });
    },

    updateErrors(errors) {
      Object.keys(errors).forEach((key) => {
        if (key === 'user') {
          const user = errors[key];
          Object.keys(user).forEach((k) => {
            const error = Utils.unpackErrors(user[k]);
            this.$set(this.profile.user, `${k}_error`, error);
          });
        } else {
          const error = Utils.unpackErrors(errors[key]);
          this.$set(this.profile, `${key}_error`, error);
        }
      });
    },

    actionSaveChanges() {
      console.debug('saving changes');
      const payload = this.profile;
      this.$store.dispatch('profile/updateProfile', payload)
        .then((response) => {
          const { data, success, errors } = response;
          if (success) {
            this.updateData(data);
          } else {
            this.updateErrors(errors);
          }
        });
    },

    actionDiscardChanges() {
      console.debug('discarding changes');
      this.getProfile();
    },
  },
};
</script>

<style>

</style>
